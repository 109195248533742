import React, { useContext } from "react";
import { TranslationContext } from "../../TranslationContext";
import { Link } from "react-router-dom";
import "./Service.css";
import partyImg from "../images/party/fiesta1.jpg";

const Service = () => {
  const { translation } = useContext(TranslationContext);

  return (
    <div className="service-container">
      <div className="vertical-text">
        <h1>{translation.serviceVertical}</h1>
      </div>

      <div className="text-content">
        <h1>{translation.serviceH1}</h1>
        <p>{translation.serviceP}</p>
      </div>

      <div className="image-button-container">
        <img src={partyImg} alt="Party" className="party-image" />
        <Link
          to={{
            pathname: "/Galería",
            state: { scrollTo: "party-section", preventScrollTop: true }, // Add the flag
          }}
          className="btn-service"
        >
          {translation.serviceMorePhotos}
        </Link>
      </div>
    </div>
  );
};

export default Service;
