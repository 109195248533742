import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { TranslationContext } from "../../TranslationContext";
import "./Reservacion.css";

function Reservacion() {
  const { translation } = useContext(TranslationContext);

  return (
    <div className="container-reservar">
      <div className="reservar-content">
      <h3>{translation.callUs}</h3>
      <div className="reservas-number">
        <FontAwesomeIcon className="icon" icon={faMobileScreen} />
        <a href="tel:922416600">922 416 600</a>
        </div></div>
    </div>
  );
}

export default Reservacion;
