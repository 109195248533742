import React from 'react'

import Hero from './Hero';



function Home() {
  return (
    <div className='container'>
    
    <Hero />

    
    </div>
  )
}

export default Home;
