import React from "react";

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/pages/About";
import Menu from "./components/pages/Menu";
import Reservacion from "./components/pages/Reservacion";
import Galería from "./components/pages/Galería";
import Contacto from "./components/pages/Contacto";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Policy from "./components/pages/Policy";


function App() {

 
  return (
    <Router>
      <div className="container">
        <Navbar />
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Menu" element={<Menu />} />
          <Route path="/About" element={<About />} />
          <Route path="/Reservacion" element={<Reservacion />} />
          <Route path="/Galería" element={<Galería />} />
          <Route path="/Contacto" element={<Contacto />} />
          <Route path="/Policy" element={<Policy />} />
          
        </Routes>
        <div className='divider'></div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
