import React, { createContext, useState, useEffect } from 'react';
import en from './translations/en.json';
import es from './translations/es.json';
import cz from './translations/cz.json';
import de from './translations/de.json';

const translations = { en, es, cz, de };


export const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState('es');
  const [translation, setTranslation] = useState(translations[language]);

  useEffect(() => {
    setTranslation(translations[language]);
  }, [language]);

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <TranslationContext.Provider value={{ translation, switchLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};