import React from "react";
import "./Policy.css";

function Policy() {
  return (
    <div className="policy-content">
      
        <section id="policies">
            <h2>Our Policies</h2>
            <div class="policy" id="privacy-policy">
                <h3>Privacy Policy</h3>
                <p>At Parrilla Las Nieves, we respect your privacy and are committed to protecting your personal information.</p>
                <ul>
                    <li><strong>Information We Collect:</strong>
                        <ul>
                            <li><strong>Personal Information:</strong> When you make a reservation, we collect your name and phone number.</li>
                            <li><strong>Contact Information:</strong> If you contact us via email, we will have access to your email address.</li>
                        </ul>
                    </li>
                    <li><strong>How We Use Your Information:</strong>
                        <ul>
                            <li><strong>Reservation Management:</strong> We use your name and phone number to manage reservations.</li>
                            <li><strong>Communication:</strong> We may use your email address to respond to inquiries or send you information about your reservation.</li>
                        </ul>
                    </li>
                    <li><strong>Data Protection:</strong>
                        <ul>
                            <li><strong>Security Measures:</strong> We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of your personal information.</li>
                            <li><strong>Data Sharing:</strong> We do not share your personal information with any third parties.</li>
                        </ul>
                    </li>
                    <li><strong>Contact Us:</strong> If you have any questions or concerns about our privacy policy, please contact us at: <a href="mailto:parrillalasnieves@yahoo.es">parrillalasnieves@yahoo.es</a></li>
                </ul>
            </div>
            <div class="policy" id="reservation-policy">
                <h3>Reservation Policy</h3>
                <p><strong>Making a Reservation:</strong> You can make a reservation by calling us. We will ask for your name and phone number to secure your reservation.</p>
                <p><strong>No-Show Policy:</strong> If you do not show up for your reservation, no penalties will be applied.</p>
            </div>
            <div class="policy" id="refund-cancellation-policy">
                <h3>Refund and Cancellation Policy</h3>
                <p><strong>Refunds and Cancellations:</strong> As we do not take deposits for reservations, there is no need for a refund or cancellation policy.</p>
            </div>
            <div class="policy" id="health-safety-policy">
                <h3>Health and Safety Policy</h3>
                <p><strong>Safety Measures:</strong> We adhere to strict hygiene practices to ensure the safety and well-being of our customers and staff.</p>
                <p><strong>Allergen Information:</strong> Please consult our staff at the restaurant or bar regarding allergens in our menu items.</p>
            </div>
            <div class="policy" id="allergy-dietary-policy">
                <h3>Allergy and Dietary Information Policy</h3>
                <p><strong>Handling Allergens:</strong> Customers are encouraged to consult our staff regarding any allergens in our dishes. We strive to accommodate dietary needs to the best of our ability.</p>
            </div>
            <div class="policy" id="payment-policy">
                <h3>Payment Policy</h3>
                <p><strong>Accepted Payment Methods:</strong> We accept all major credit and debit cards. Cash payments are also accepted.</p>
            </div>
            <div class="policy" id="company-info">
                <h3>Company Information</h3>
                <p><strong>Company Details:</strong></p>
                <ul>
                    <li><strong>Name:</strong> Explotaciones Mendoza y Rodríguez S.L.</li>
                    <li><strong>NIF / CIF:</strong> B76595545</li>
                    <li><strong>Address:</strong> Carretera las Nieves, 17, 38700 Santa Cruz de la Palma, Santa Cruz de Tenerife, Spain</li>
                    <li><strong>Email:</strong> <a href="mailto:parrillalasnieves@yahoo.es">parrillalasnieves@yahoo.es</a></li>
                </ul>
            </div>
        </section>
    
  </div>
  );
}

export default Policy;