import React, { useState, useContext, useRef, useEffect } from 'react';
import { TranslationContext } from '../TranslationContext';

import './LanguageSwitcher.css';
import languageIcon from "../translator (1).png";
function LanguageSwitcher() {
  const { switchLanguage, language } = useContext(TranslationContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

 
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="language-switcher" ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
      <img
          src={languageIcon}
          alt="Language Switcher"
          className="language-icon"
        />
      </button>
      {dropdownOpen && (
        <div className="dropdown-menu">
          <button
            onClick={() => switchLanguage('en')}
            className={language === 'en' ? 'active' : ''}
          >
            EN
          </button>
          <button
            onClick={() => switchLanguage('es')}
            className={language === 'es' ? 'active' : ''}
          >
            ES
          </button>
          <button
            onClick={() => switchLanguage('cz')}
            className={language === 'cz' ? 'active' : ''}
          >
            CZ
          </button>
          <button
            onClick={() => switchLanguage('de')}
            className={language === 'de' ? 'active' : ''}
          >
            DE
          </button>
        </div>
      )}
    </div>
  );
}

export default LanguageSwitcher;