import { TranslationContext } from "../../TranslationContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./GalleryPreview.css";

import galleryImg1 from "../images/food/plato2.jpg";
import galleryImg2 from "../images/food/plato4.jpg";
import galleryImg3 from "../images/food/postre1.jpg";

const GalleryPreview = () => {
  const { translation } = useContext(TranslationContext);

  return (
    <div className="galleryPreview-container">
      <div className="vertical-text">
        <h1>{translation.gallery}</h1>
      </div>
      <div className="gallery-images">
        <img src={galleryImg1} alt="Gallery 1" />
        <img src={galleryImg2} alt="Gallery 2" />
        <img src={galleryImg3} alt="Gallery 3" />
       
        <Link
          to={{
            pathname: "/Galería",
            state: { scrollTo: "gallery-section" },
          }}
          className="btn see-more"
        >
          {translation.seeMore}
        </Link>
      </div>
    </div>
  );
};

export default GalleryPreview;
