import React from "react";
import "./Menu.css";

import flagImg from "../images/es.svg";
import flagImg1 from '../images/gb.svg';  
import flagImg2 from '../images/de.svg';

function Menu() {
  return (
    <div className="menu">
      <div className="buttons-container">
        <a 
          href="https://drive.google.com/file/d/17KQexK3jvAjylfH0M0l0nxxJzgW5gqAu/view" 
          className="flag-button" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img src={flagImg} alt="Spanish Flag" />
        </a>
        <a 
          href="https://drive.google.com/file/d/15Qp1i-ufqw5orqVO5VuFYJFeCIoCPn2B/view" 
          className="flag-button" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img src={flagImg1} alt="English Flag" />
        </a>
        <a 
          href="https://drive.google.com/file/d/1gTfAgMROgBIXv7QrVsbPoZnh7jat4c2n/view" 
          className="flag-button" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img src={flagImg2} alt="German Flag" />
        </a>
      </div>
    </div>
  );
}

export default Menu;
