import React, {useContext} from "react";
import "./Contacto.css";
import { TranslationContext } from '../../TranslationContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

function Contacto() {
  const { translation } = useContext(TranslationContext);
  return (
    <div className="contact-content">
      <div className="contact-text">
        <h1 className="h1-contact">{translation.contactInfo}</h1>

        <div className="container-elements">
          <div className="contact-element">
            <FontAwesomeIcon className="icon" icon={faMobileScreen} />
            <a href="tel:922 416 600"> 922 416 600</a>
          </div>

          <div className="contact-element">
            <FontAwesomeIcon className="icon" icon={faEnvelope} />{" "}
            <a href="mailto:parrillalasnieves@yahoo.es">
              parrillalasnieves@yahoo.es
            </a>
          </div>
          <div className="contact-element">
            <FontAwesomeIcon className="icon" icon={faLocationDot} />
            <a href="https://maps.app.goo.gl/hahJwXp9uFUqsb4o7">
            Barrio Nieves, 2, 38700 Las Nieves, Santa Cruz de La Palma, Santa Cruz de Tenerife
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacto;
