import React, { useContext } from "react";
import { TranslationContext } from "../../TranslationContext";

import "./ReservarMain.css";

const ReservarMain = () => {
  const { translation } = useContext(TranslationContext);
  return (
    <div className="reservarMain-container">
    
       <div className="vertical-text"><h1>{translation.reservation}</h1></div>
          
       
        <div className="reservarMain-title">
          <h1>{translation.reservationH1}</h1>
        </div>
        
        <a href="tel:922416600" className="btn">{translation.reserve}</a>
      </div>
    
  );
};

export default ReservarMain;
