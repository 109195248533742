import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Galería.css";

const importAll = (r) => r.keys().map(r);

const foodImages = importAll(
  require.context("../images/food", false, /\.(png|webp|jpe?g|svg)$/)
);
const restaurantImages = importAll(
  require.context("../images/restaurant", false, /\.(png|webp|jpe?g|svg)$/)
);
const partyImages = importAll(
  require.context("../images/party", false, /\.(png|webp|jpe?g|svg)$/)
);

function Galería() {
  const location = useLocation();

  useEffect(() => {
    const preventScrollTop = location.state?.preventScrollTop;
    const scrollToSection =
      location.state?.scrollTo || location.hash?.substring(1);

    const scrollAfterLoad = () => {
      if (scrollToSection) {
        const section = document.getElementById(scrollToSection);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      } else if (!preventScrollTop) {
        window.scrollTo(0, 0); // Scroll to top if preventScrollTop is not true
      }
    };

    setTimeout(scrollAfterLoad, 100);
  }, [location]);

  return (
    <div className="page-galeria">
      <div className="container-galeria">
        <div id="gallery-section" className="gallery-grid">
          {foodImages.map((src, index) => (
            <div key={index} className="gallery-item">
              <img src={src} alt={`Comida ${index + 1}`} loading="lazy" />
            </div>
          ))}
        </div>

        <div className="gallery-grid">
          {restaurantImages.map((src, index) => (
            <div key={index} className="gallery-item">
              <img src={src} alt={`Restaurante ${index + 1}`} loading="lazy" />
            </div>
          ))}
        </div>

        <div id="party-section" className="gallery-grid">
          {partyImages.map((src, index) => (
            <div key={index} className="gallery-item">
              <img src={src} alt={`Fiesta ${index + 1}`} loading="lazy" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Galería;
