import React, { useContext } from "react";
import "./About.css";
import { TranslationContext } from "../../TranslationContext";
import restaurantPhoto from "../images/restaurant1.jpg";
import meatPhoto from "../images/a_carne3.jpg";

function About() {
  const { translation } = useContext(TranslationContext);

  return (
    <div className="about-container">
      <div className="vertical-text">
      <h1>{translation.aboutH1}</h1>  
      </div>
      
      <div className="km0-section">
        <div className="km0-text">
          <h1>{translation.Km0H1}</h1>
          <p>
          {translation.Km0}
          </p>
        </div>
        <img src={meatPhoto} alt="Meat" className="km0-image" />
      </div>
      <div className="years-section">
     
      <img src={restaurantPhoto} alt="Restaurant" className="years-image" />
        <div className="years-text">
          <h1>{translation.YearsH1}</h1>
          <p>
          {translation.Years}
          </p>
        </div>
       
      </div>
    </div>
  );
}

export default About;
