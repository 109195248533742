import React, { useState, useRef, useEffect, useContext } from "react";
import "./Navbar.css";
import LanguageSwitcher from "./LanguageSwitcher";
import { TranslationContext } from "../TranslationContext";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHome } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  const [isActive, setIsActive] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { translation } = useContext(TranslationContext);

  const toggleMenu = () => setIsActive((prev) => !prev);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavLinkClick = (path, preventScrollTop = false) => {
    setIsActive(false);
    navigate(path, { state: { preventScrollTop } }); // Pass preventScrollTop state to navigation
  
    const wrapper = document.querySelector(".container");
    if (wrapper) {
      wrapper.style.overflow = "auto";
    }
  
    if (!preventScrollTop) { // Only scroll to the top if preventScrollTop is false
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
  
        if (wrapper) {
          wrapper.style.overflow = "hidden";
        }
      }, 100);
    }
  };
  

  return (
    <div className="navbar">
      <FontAwesomeIcon
        icon={faBars}
        className="menu-toggle"
        onClick={toggleMenu}
      />
      <ul ref={menuRef} className={`navbar-list ${isActive ? "active" : ""}`}>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            onClick={() => handleNavLinkClick("/")}
          >
            <FontAwesomeIcon icon={faHome} />
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Menu"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            onClick={() => handleNavLinkClick("/Menu")}
          >
            {translation.menu}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Galería"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            onClick={(e) => {
              const preventScrollTop = e?.state?.preventScrollTop;
              handleNavLinkClick("/Galería", preventScrollTop);
            }}
          >
            {translation.gallery}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Reservacion"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            onClick={() => handleNavLinkClick("/Reservacion")}
          >
            {translation.reserve}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Contacto"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
            onClick={() => handleNavLinkClick("/Contacto")}
          >
            {translation.contact}
          </NavLink>
        </li>
        <li>
          <LanguageSwitcher />
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
